<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaIcon } from '@lyka/ui'
import CheckoutOrderSummaryInclusions from './CheckoutOrderSummaryInclusions.vue'
import CheckoutOrderSummaryTreats from './CheckoutOrderSummaryTreats.vue'
import CheckoutOrderSummaryDeliveryDetails from './CheckoutOrderSummaryDeliveryDetails.vue'
import CheckoutOrderSummaryDogMealPlan from './CheckoutOrderSummaryDogMealPlan.vue'
import CheckoutOrderSummaryMealPlan from './CheckoutOrderSummaryMealPlan.vue'
import CheckoutIntroductoryPlanExplainer from './CheckoutIntroductoryPlanExplainer.vue'
import CheckoutOrderFreeTopper from './CheckoutOrderFreeTopper.vue'
import CheckoutOrderFreeGutHelper from './CheckoutOrderFreeGutHelper.vue'
import CheckoutOrderSummaryTrialPlan from './CheckoutOrderSummaryTrialPlan.vue'
import { useDogsStore } from '@/stores/dogs'
import { type MealPlan, MealPlanType, isTrialPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { useFreeFussyMealTopper } from '@/composables/useFreeFussyMealTopper'
import { useFreeGutHelper } from '@/composables/useFreeGutHelper'
import type { Recipe } from '@/models/Recipe'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
  everyBox?: boolean
  treatQuantities?: Record<number, number>
  discount?: number
}>()

const dogsStore = useDogsStore()
const freeFussyMealTopper = useFreeFussyMealTopper()
const freeGutHelper = useFreeGutHelper()

const expanded = ref(false)

const toggle = (): void => {
  expanded.value = !expanded.value
}

const isIntroductoryPlan = computed(() => {
  return [MealPlanType.Starter, MealPlanType.Trial].includes(props.mealPlan.type)
})

const trial = computed(() => {
  return isTrialPlanType(props.mealPlan.type)
})

const trialRecipes = computed<Array<Recipe>>(() => {
  const recipes = new Set<Recipe>()

  for (const dog of dogsStore.getDogs()) {
    for (const recipe of dog.recipes) {
      recipes.add(recipe)
    }
  }

  return Array.from(recipes)
})
</script>

<template>
  <div class="tw-space-y-5">
    <div>
      <h4 class="tw-font-bold md:tw-h4">Order summary</h4>
    </div>

    <CheckoutOrderSummaryMealPlan :meal-plan="mealPlan" />

    <CheckoutIntroductoryPlanExplainer v-if="isIntroductoryPlan" :meal-plan="mealPlan" />

    <details id="summary-toggle" @toggle="toggle">
      <summary>
        <span
          class="tw-text-green tw-flex tw-items-center tw-cursor-pointer tw-underline hover:tw-no-underline tw-gap-2"
        >
          {{ expanded ? 'Hide' : 'Show' }} summary
          <LykaIcon name="SelectDownThin" :class="{ 'tw-rotate-180': expanded }" />
        </span>
      </summary>

      <div class="tw-divide-y tw-divide-mint-green">
        <CheckoutOrderSummaryTrialPlan v-if="trial" :recipes="trialRecipes" :meal-plan="mealPlan" />

        <div v-for="(mealWeight, index) in mealWeights" v-else :key="mealWeight.key">
          <CheckoutOrderSummaryDogMealPlan
            :meal-weight="mealWeight"
            :meal-plan="mealPlan"
            :recipes="dogsStore.dogRecipes(index)"
          />
        </div>

        <CheckoutOrderSummaryTreats :every-box="everyBox" :treat-quantities="treatQuantities" />

        <CheckoutOrderSummaryInclusions :meal-plan="mealPlan" />

        <CheckoutOrderSummaryDeliveryDetails />
      </div>
    </details>

    <CheckoutOrderFreeGutHelper v-if="freeGutHelper.enabled.value" />

    <CheckoutOrderFreeTopper
      v-if="freeFussyMealTopper.enabled.value"
      :treat="freeFussyMealTopper.originalProduct.value!"
    />
  </div>
</template>
