import z from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type ModifySchema, Step } from './Step'
import { useRecipesStep } from './recipes'
import { registerStep } from './stepRegistry'
import { useTreatsStep } from './treats'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'
import { MealPlanType } from '@/models/MealPlan'
import { GTMEventAction, useGTM } from '@/composables/useGTM'

const Schema = z.object({
  plan: z.object({
    type: z.nativeEnum(MealPlanType),
  }),
})

export type PlanStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    plan: {
      type: MealPlanType | null
    }
  }
>

class PlanStep extends Step<PlanStepData> {
  name = StepName.Plan
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Your Plan'

  showSteps = false

  /** Whether to show the coupon when this step is active */
  showCouponBanner = true

  /** Whether to persist the coupon banner so that it doesn't automatically dismiss */
  persistCouponBanner = true

  constructor() {
    super()
    this.loadState()

    // If a dog is added reset the selected plan as the old plan may not be valid for multiple dogs
    this.dogsStore.on('added', () => {
      this.data.plan.type = null
    })
  }

  onComplete(): void {
    super.onComplete()

    if (this.data.plan.type) {
      useGTM().sendAction(GTMEventAction.SELECTED_PLAN, this.data.plan.type)
      useSegment().track('Plan Completed', { planName: this.data.plan.type })
    }
  }

  initialState(): PlanStepData {
    return {
      plan: {
        type: null,
      },
    }
  }

  prev(): Step {
    return useRecipesStep()
  }

  next(): Step {
    return useTreatsStep()
  }
}

export const usePlanStep = registerStep(PlanStep)
