import z from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type ModifySchema, Step } from './Step'
import { registerStep } from './stepRegistry'
import { useContactDetailsStep } from './contactDetails'
import { useNoRecipesStep } from './noRecipes'
import { useRecipesStep } from './recipes'
import { useIllnessesStep } from './illnesses'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'
import { useRecommendedMeals } from '@/composables/useRecommendedMeals'

const Schema = z.object({
  suitableRecipes: z.boolean(),
})

export type LoadingPlanStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    suitableRecipes: boolean | null
  }
>

// This step shows when we determine the suitable recipes for a user.
// We show a loading state so the user thinks we're thinking long and hard
// about their plan.
class LoadingPlanStep extends Step<LoadingPlanStepData> {
  name = StepName.LoadingPlan
  schema = Schema
  title = 'Your Plan'
  dogsStore = useDogsStore()
  history = false

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('allergiesUpdated', () => this.resetState())
    this.dogsStore.on('allergiesRemoved', () => this.resetState())
    this.dogsStore.on('illnessesUpdated', () => this.resetState())
    this.dogsStore.on('illnessesRemoved', () => this.resetState())
  }

  onComplete(): void {
    super.onComplete()

    useSegment().track('Recipes Calculated', {
      dogs: useRecipesStep().data.dogs.map((_, index) => {
        const suitableRegularRecipes = useRecommendedMeals().suitableRecipesForDog(index)
        const suitableTherapeuticRecipes = useRecommendedMeals().therapeuticRecipesForDog(index)

        const suitableRecipes = [...suitableRegularRecipes, ...suitableTherapeuticRecipes]
        const unsuitableRecipes = useRecommendedMeals().suitableRecipesForDog(index)

        return {
          suitableRecipes: suitableRecipes.map(({ name }) => name),
          unsuitableRecipes: unsuitableRecipes.map(({ name }) => name),
        }
      }),
    })
  }

  initialState(): LoadingPlanStepData {
    return {
      suitableRecipes: null,
    }
  }

  prev(): Step {
    return useIllnessesStep()
  }

  next(): Step {
    if (this.data.suitableRecipes) {
      return useContactDetailsStep()
    }

    return useNoRecipesStep()
  }
}

export const useLoadingPlanStep = registerStep(LoadingPlanStep)
