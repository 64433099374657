<script setup lang="ts">
import { capitalize, computed, onMounted, reactive, ref } from 'vue'
import { clone } from 'remeda'

import { LykaAccordion } from '@lyka/ui'
import StepNavigation from '../StepNavigation.vue'
import RecipesFreeGutHelperCard from './recipes/cards/RecipesFreeGutHelperCard.vue'
import RecipesFreeFussinesTopperCard from './recipes/cards/RecipesFreeFussinesTopperCard.vue'
import { useDogsStore } from '@/stores/dogs'
import { useRecipesStore } from '@/stores/recipes'
import type { RecipesStepData } from '@/steps/recipes'
import { useRecommendedMeals } from '@/composables/useRecommendedMeals'
import type { Recipe } from '@/models/Recipe'
import { addLineBreaks } from '@/utils/linebreaks'
import { useFreeFussyMealTopper } from '@/composables/useFreeFussyMealTopper'

import RecipesForDog from '@/components/steps/recipes/RecipesForDog.vue'
import RecipesForDogTabs from '@/components/steps/recipes/RecipesForDogTabs.vue'
import RecipesForDogTraits from '@/components/steps/recipes/RecipesForDogTraits.vue'
import { useFreeGutHelper } from '@/composables/useFreeGutHelper'

const props = defineProps<{
  stepData: RecipesStepData
  valid: boolean
}>()

const emit = defineEmits<{
  (e: 'previous'): void
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
}>()

const recipeStore = useRecipesStore()
const dogsStore = useDogsStore()
const recommendedMeals = useRecommendedMeals()
const freeFussyMealTopper = useFreeFussyMealTopper()
const freeGutHelper = useFreeGutHelper()

const FAQs = [
  {
    question: 'What are some of the changes I may see in pupper when they switch to Lyka?',
    answer: `At Lyka, we’re all about that fresh food difference. Puppers that have made the switch to Lyka report on a lot of positive changes. Increased enthusiasm during meal times translates to increased energy throughout the day. Other noticeable changes include shinier and healthier coats, improved dental health, improved digestion and less stinky poos.\n\nYou may also notice that your pupper has smaller poos. This is perfectly natural since Lyka doesn’t contain fillers. Your pupper is merely digesting and using a larger portion of the food than before.`,
  },
  {
    question: 'What does it mean that Lyka is complete and balanced?',
    answer: `Complete and balanced means that Lyka exceeds all of the nutritional requirements your pupper needs, from macronutrients (protein, fat and carbohydrates), to micronutrients (vitamins and minerals). Our recipes follow the AAFCO nutritional standards and are designed to grow with your dog from their puppy years, all the way to their golden oldies. Our recipes are also crossed checked with the FEDIAF (European Pet Food Industry Federation) standards.\n\nWe work with leading holistic vets, as well as an Australian veterinary nutritionist, combining the best of natural medicine and scientific research to formulate our recipes. We craft our recipes to help optimise your pup’s health for a longer, happier life.\n\nLyka recipes undergo testing through nutritional software and food laboratories to guarantee their nutritional content.`,
  },
  {
    question: 'What if I still have dog food left?',
    answer: `No trouble at all! When moving your pup over to our Lyka meals, we do recommend a gradual transition (where you mix your pupper’s current food with their Lyka meals!), so that your pup’s gut microbiome has time to adjust to the new and exciting ingredients.`,
  },
  {
    question: 'How do you calculate how much my pupper should eat?',
    answer: `Our veterinary-derived algorithm calculates how much your pupper should be eating based on your pupper’s profile. Factors like their current weight, age, activity level and body shape assist our algorithm to accurately calculate the daily calorie intake that your pupper needs to maintain their ideal weight.If your pupper’s profile changes or you simply feel that we haven’t landed on the correct serving size, please let us know and we’ll make sure to find the perfect fit.\n\nSome Lyka recipes are leaner, richer or higher in calories than others and therefore, intake recommendations are based on an average. If you switch up your pup’s meal plan by adding or removing recipes, different to what was recommended to you, we suggest keeping a close eye on your pup’s weight and body shape for any indications that they may need to go up or down in pouch sizes.`,
  },
  {
    question: 'Is there a minimum subscription time required?',
    answer:
      'Not at all. You can pause or cancel your subscription at any time directly from your Lyka account. You can also switch between different plan options. We only ask that you make any changes before your next scheduled payment, so that your upcoming order is not processed.',
  },
  {
    question: 'How much freezer room should I make for Lyka?',
    answer:
      'Every 500 grams of Lyka is about the size of a standard ice cube tray. For example, if your pupper’s serving is 500g per day, and you’re receiving a 2-week delivery, you need space for about 14 ice cube trays.',
  },
] as const

const data = reactive(clone(props.stepData))

const dogs = dogsStore.getDogs()
const dogIndex = ref(0)

const dogTraits = computed(() => {
  const allergies = dogsStore.dogAllergies(dogIndex.value).map(({ name }) => `${capitalize(name)} allergy`)
  const illnesses = dogsStore.dogIllnesses(dogIndex.value).map(({ name }) => name)
  const breeds = dogsStore.dogBreeds(dogIndex.value).map(({ name }) => name)
  const ageType = dogsStore.dogAgeType(dogIndex.value)
  const fussinessLevel = dogsStore.dogFussinessLevel(dogIndex.value)?.customerText
  const activityLevel = dogsStore.dogActivityLevel(dogIndex.value)?.customerText

  const bodyShape = dogsStore.dogBodyShape(dogIndex.value)
  const bodyShapeText = bodyShape?.name !== 'ideal' ? bodyShape?.customerText : ''

  return [...breeds, ageType, fussinessLevel, activityLevel, bodyShapeText, ...allergies, ...illnesses].filter(
    (trait) => !!trait,
  ) as string[]
})

const currentDog = computed(() => dogs[dogIndex.value])

const suitableRecipes = computed<Recipe[]>(() => recommendedMeals.suitableRecipesForDog(dogIndex.value))
const unsuitableRecipes = computed<Recipe[]>(() => recommendedMeals.unsuitableRecipesForDog(dogIndex.value))
const therapeuticRecipes = computed<Recipe[]>(() => recommendedMeals.therapeuticRecipesForDog(dogIndex.value))

const unsuitableTherapeuticRecipes = computed<Recipe[]>(() =>
  recommendedMeals.unsuitableTherapeuticRecipesForDog(dogIndex.value),
)

const selectedRecipes = computed<Recipe[]>(
  () => data.dogs[dogIndex.value]?.recipes.map(recipeStore.findRecipe).filter(Boolean) as Recipe[],
)

const dogViews = computed(() => data.dogs.map((dog) => dog.viewed ?? false))
const areAllDogsViewed = computed(() => dogViews.value.every((viewed) => viewed))

const dogRecipesUpdated = (recipes: Recipe[]): void => {
  const recipeIds = recipes.map(({ id }) => id)

  const dogData = data.dogs[dogIndex.value] ?? {}
  data.dogs[dogIndex.value] = { ...dogData, recipes: recipeIds }

  emit('save', data)
}

const switchToNthDog = (index: number): void => {
  dogIndex.value = index

  const dogData = data.dogs[index] ?? { recipes: [] }
  data.dogs[index] = { ...dogData, viewed: true }

  emit('save', data)
}

const next = (): void => {
  if (areAllDogsViewed.value) {
    return emit('submit', data)
  }

  const unviewedDogIndex = dogViews.value.findIndex((isViewed) => isViewed === false)

  switchToNthDog(unviewedDogIndex)
}

const getOnlyFirstDogName = (alternative: string): string => {
  const [first, ...others] = dogs

  if (first && !others.length) {
    return `${first.name}'s`
  }

  return alternative
}

const multipleDogs = computed<boolean>(() => {
  return dogs.length > 1
})

onMounted(() => {
  switchToNthDog(0)
})
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <div
      class="sm:-tw-mt-16 -tw-mt-6 tw-flex tw-flex-col tw-items-center tw-gap-5 tw-py-10 md:tw-py-12"
      :class="{
        'tw-bg-mint-green/50': dogs.length === 1,
      }"
    >
      <div class="tw-max-w-2xl tw-mx-auto tw-space-y-6 tw-px-6">
        <h2 id="recipes-heading" class="tw-font-stylized tw-text-7xl tw-leading-tighter tw-px-6 tw-text-center">
          {{ getOnlyFirstDogName(`Your Dogs'`) }} Recommendation
        </h2>

        <p class="tw-font-light tw-text-center">
          To get you started, we’ve recommended meals ideal for {{ getOnlyFirstDogName("each dog's") }} profile.
        </p>

        <RecipesForDogTraits v-if="!multipleDogs" :traits="dogTraits" />

        <div v-if="freeFussyMealTopper.enabled.value">
          <div :class="{ '-tw-mb-14 md:-tw-mb-4': !multipleDogs }">
            <RecipesFreeFussinesTopperCard
              :treat="freeFussyMealTopper.originalProduct.value!"
              :dog-names="freeFussyMealTopper.fussyDogNames.value"
            />
          </div>
        </div>

        <div v-else-if="freeGutHelper.visible.value">
          <div :class="{ '-tw-mb-14 md:-tw-mb-4': !multipleDogs }">
            <RecipesFreeGutHelperCard :treat="freeFussyMealTopper.originalProduct.value!" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="multipleDogs"
      class="tw-border-b tw-border-mint-green tw-w-full tw-px-8 tw-flex tw-justify-center tw-max-w-3xl tw-mx-auto"
    >
      <RecipesForDogTabs :dogs="dogs" :dog-views="dogViews" :dog-index="dogIndex" @dog-switch="switchToNthDog" />
    </div>

    <RecipesForDog
      v-if="currentDog"
      :dog="currentDog"
      :dog-traits="dogTraits"
      :selected-recipes="selectedRecipes"
      :suitable-recipes="suitableRecipes"
      :unsuitable-recipes="unsuitableRecipes"
      :therapeutic-recipes="therapeuticRecipes"
      :unsuitable-therapeutic-recipes="unsuitableTherapeuticRecipes"
      :multiple-dogs="multipleDogs"
      @update="dogRecipesUpdated"
    />

    <div class="tw-mt-16 tw-max-w-3xl tw-self-center">
      <div class="tw-space-y-6 tw-px-6">
        <h3 class="tw-text-center tw-font-stylized tw-text-4xl md:tw-text-6xl">Frequently asked questions</h3>

        <div class="tw-space-y-3">
          <LykaAccordion v-for="(faq, index) in FAQs" :key="index" :title="faq.question">
            <div class="tw-space-y-4">
              <p v-html="addLineBreaks(faq.answer)" />
            </div>
          </LykaAccordion>
        </div>
      </div>
    </div>
  </div>

  <StepNavigation
    :next-text="areAllDogsViewed ? 'Continue to Plans' : 'Next Dog'"
    @previous="emit('previous')"
    @next="next"
  />
</template>
