<script setup lang="ts">
import { capitalize, computed, onMounted, reactive, ref, watch } from 'vue'
import { clone } from 'remeda'
import { LykaContainer } from '@lyka/ui'
import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import TrustpilotReviews from '../TrustpilotReviews.vue'
import MiniHr from '../common/MiniHr.vue'
import AnyQuestion from '../common/AnyQuestion.vue'
import StepNavigation from '../StepNavigation.vue'
import TickIcon from '../icons/TickIcon.vue'
import PlanFirstBox from './plan/PlanFirstBox.vue'
import PlanSocialGiftingStarter from './plan/PlanSocialGiftingStarter.vue'
import PlanOptions from './plan/PlanOptions.vue'
import PlanStepLoading from './plan/PlanStepLoading.vue'
import { useNoCommonRecipesEvent } from '@/composables/events/segment/useNoCommonRecipes'
import { type MealPlan, MealPlanType, TRIAL_BOX_LARGE_SERVING_SIZE_THRESHOLD, getMealPlanName } from '@/models/MealPlan'
import type { PlanStepData } from '@/steps/plan'
import { AcquisitionPage, useAcquisitionData } from '@/composables/useAcquisitionData'
import { useMealPlansStore } from '@/stores/mealPlans'
import PlanSocialGiftingWhatHappensNext from '@/components/steps/plan/PlanSocialGiftingWhatHappensNext.vue'
import { useCoupons } from '@/composables/useCoupons'
import { usePlansCalculatedEvent } from '@/composables/events/segment/usePlansCalculatedEvent'
import { useRecipesStore } from '@/stores/recipes'
import { isTherapeuticRecipe } from '@/models/Recipe'

const props = defineProps<{
  stepData: PlanStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const mealPlanStore = useMealPlansStore()
const recipesStore = useRecipesStore()
const coupons = useCoupons()
const multiDogTrialBoxEnabled = ref(false)

const data = reactive(clone(props.stepData))
const loading = ref(true)

const hasTherapeuticRecipes = computed<boolean>(() => {
  return recipesStore.selectedRecipes.some(isTherapeuticRecipe)
})

const hasSocialGiftingCoupon = computed(() => coupons.socialGiftingCoupon.value)
const inMultiDogTrialBoxExperiment = useExperiments().inExperiment('bab-multi-dog-trial-box')

const starterPlan = computed(() => {
  return mealPlanStore.mealPlans.find((plan) => plan.type === 'starter')
})

const nextButtonLabel = computed(() => {
  const { type } = props.stepData.plan

  if (type) {
    const name = capitalize(getMealPlanName(type).replace(' plan', ''))

    return `Choose ${name}`
  }

  return 'Select a plan'
})

const TRIAL_BOX_MIN_SERVING_WEIGHT = 200 as const

const MAX_DOGS_TRIAL_BOX = 2 as const

const hasCommonDogRecipes = computed<boolean>(() => {
  const [firstDog, secondDog] = recipesStore.selectedRecipesByDog

  if (firstDog === undefined) {
    return false
  }

  if (secondDog === undefined) {
    return true
  }

  return firstDog.some((recipe) => secondDog.includes(recipe))
})

const trialBoxMealPlanType = computed<MealPlanType | undefined>(() => {
  const dogCount = mealPlanStore.mealWeights.length
  const { servingWeight = 0 } = mealPlanStore.mealWeights[0]!

  // Always return 14x200g for 2 dogs
  if (dogCount === MAX_DOGS_TRIAL_BOX) {
    if (!inMultiDogTrialBoxExperiment) {
      return undefined
    }

    if (!hasCommonDogRecipes.value) {
      return undefined
    }

    return MealPlanType.Trial14x200g
  }

  // Exclude >2 dogs from trial box
  if (dogCount > MAX_DOGS_TRIAL_BOX) {
    return undefined
  }

  if (servingWeight < TRIAL_BOX_MIN_SERVING_WEIGHT) {
    return undefined
  }

  if (servingWeight >= TRIAL_BOX_LARGE_SERVING_SIZE_THRESHOLD) {
    return MealPlanType.Trial14x200g
  }

  return MealPlanType.Trial7x200g
})

const visibleMealPlans = computed<MealPlan[]>(() => {
  const trialBoxMealPlan = mealPlanStore.mealPlans.find((plan) => plan.type === trialBoxMealPlanType.value)
  const visiblePlans = mealPlanStore.mealPlans.filter((plan) => !plan.hidden)

  if (trialBoxMealPlan) {
    return visiblePlans.map((plan) => {
      // Switch the starter plan with the trial box plan
      if (plan.type === MealPlanType.Starter) {
        return trialBoxMealPlan
      }

      return plan
    })
  }

  return visiblePlans
})

const recipes = computed(() => recipesStore.selectedRecipes)

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const onPlanSelected = async (plan: MealPlan | undefined, submit = true): Promise<void> => {
  data.plan.type = plan?.type ?? null

  if (submit) {
    emits('submit', data)
  } else {
    emits('save', data)
  }
}

const autoSelectFullPlan = (): void => {
  data.plan.type = MealPlanType.Full

  emits('save', data)
}

onMounted(async () => {
  try {
    const { mealPlans, mealWeights } = await mealPlanStore.loadMeals()

    multiDogTrialBoxEnabled.value = await useFlagsmith().isEnabledAsync('feature-multi-dog-trial-box')

    usePlansCalculatedEvent({ mealPlans, mealWeights }).send()

    if (!data.plan.type) {
      autoSelectFullPlan()
    }
  } finally {
    loading.value = false

    useAcquisitionData().send(AcquisitionPage.Plan)

    if (!hasCommonDogRecipes.value) {
      useNoCommonRecipesEvent().send()
    }
  }
})
</script>

<template>
  <div ref="stepNav">
    <PlanStepLoading v-if="loading" />
    <div v-else class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <!-- Social Gifting Coupon -->
          <PlanSocialGiftingStarter
            v-if="hasSocialGiftingCoupon"
            :meal-plan="starterPlan!"
            :meal-weights="mealPlanStore.mealWeights"
          />

          <PlanOptions
            :selected-meal-plan-type="data.plan.type"
            :meal-plans="visibleMealPlans"
            :meal-weights="mealPlanStore.mealWeights"
            :therapeutic="hasTherapeuticRecipes"
            @select="(mealPlan) => onPlanSelected(mealPlan, false)"
          />

          <div class="tw-space-y-8">
            <h3 class="tw-h2">Feeding with Lyka is flexible</h3>
            <ul class="tw-space-y-1 tw-text-base sm:tw-text-lg md:tw-text-xl tw-w-max tw-mx-auto">
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Cancel anytime, hassle-free</li>
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Control your delivery time</li>
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Simple swaps and changes</li>
            </ul>
          </div>
        </div>
      </LykaContainer>

      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox v-if="hasSocialGiftingCoupon" :recipes="recipes">
            <template #heading>
              Included in your
              <span class="tw-text-green tw-whitespace-nowrap">Starter Box</span>
            </template>
          </PlanFirstBox>

          <PlanSocialGiftingWhatHappensNext v-if="hasSocialGiftingCoupon" />
        </div>
      </LykaContainer>

      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox v-if="!hasSocialGiftingCoupon" :meal-plan-type="data.plan.type" :recipes="recipes" />

          <div>
            <LykaContainer size="md" class="tw-space-y-4 tw-mb-8">
              <h2 class="tw-h2 tw-mb-6"><b class="tw-text-green tw-block">Real changes.</b> Real dogs.</h2>

              <p class="tw-lead">
                Taking pride of place in dog bowls around the country, discover how Lyka is changing the game and
                improving lives.
              </p>
            </LykaContainer>

            <TrustpilotReviews />
          </div>

          <div class="tw-space-y-2 sm:tw-space-y-6">
            <MiniHr />

            <AnyQuestion center />
          </div>
        </div>
      </LykaContainer>

      <StepNavigation
        v-if="hasSocialGiftingCoupon"
        @next="onPlanSelected(starterPlan!)"
        @previous="emits('previous')"
      />
    </div>
  </div>

  <StepNavigation
    :next-text="nextButtonLabel"
    :next-disabled="!valid"
    @previous="emits('previous')"
    @next="emits('submit', data)"
  />
</template>
