export enum MealPlanType {
  Full = 'full',
  Half = 'half',
  Starter = 'starter',
  Trial = 'trial',
  Trial7x200g = 'trial-7x200g',
  Trial14x200g = 'trial-14x200g',
}

export const MealPlanTypeTrials: MealPlanType[] = [
  MealPlanType.Trial,
  MealPlanType.Trial7x200g,
  MealPlanType.Trial14x200g,
]

export interface MealPlan {
  type: MealPlanType
  frequency: number
  priceDaily: number
  priceWeekly: number
  totalPrice: number
  mealPrice: number
  hidden?: boolean
}

export const getTrialPlanContents = (type: MealPlanType): string => {
  switch (type) {
    case MealPlanType.Trial:
      return `14 × 125g`
    case MealPlanType.Trial7x200g:
      return `7 × 200g`
    case MealPlanType.Trial14x200g:
      return `14 × 200g`
    default:
      return ''
  }
}

export const getMealPlanName = (type: MealPlanType): string => {
  switch (type) {
    case MealPlanType.Full:
      return 'Full bowl plan'
    case MealPlanType.Half:
      return 'Half bowl plan'
    case MealPlanType.Starter:
      return 'Starter box'
    case MealPlanType.Trial:
    case MealPlanType.Trial7x200g:
    case MealPlanType.Trial14x200g:
      return 'Trial box'
    default:
      return ''
  }
}

export const getMealPlanTypeById = (id: number): MealPlanType | undefined => {
  switch (id) {
    case 1: {
      return MealPlanType.Full
    }
    case 2: {
      return MealPlanType.Half
    }
    case 3: {
      return MealPlanType.Starter
    }
  }

  return undefined
}

export const isTrialPlanType = (type: MealPlanType): boolean => {
  return MealPlanTypeTrials.includes(type)
}

export const isStarterPlanType = (type: MealPlanType): boolean => {
  return type === MealPlanType.Starter
}

export const TRIAL_BOX_MEAL_SIZE_GRAMS = 200 as const

export const TRIAL_BOX_LARGE_SERVING_SIZE_THRESHOLD = 600 as const
