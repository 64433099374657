<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { LykaMessage, LykaSpinner } from '@lyka/ui'
import { clone } from 'remeda'
import { useDogsStore } from '@/stores/dogs'
import { delay } from '@/utils/delay'
import { type LoadingPlanStepData } from '@/steps/loadingPlan'
import { useRecipesStep } from '@/steps/recipes'
import { useRecommendedMeals } from '@/composables/useRecommendedMeals'
import type { Recipe } from '@/models/Recipe'

const props = defineProps<{
  stepData: LoadingPlanStepData
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
  (e: 'next'): void
}>()

const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()
const recommendedMeals = useRecommendedMeals()
const skipped = ref(false)

const message = computed(() => {
  const [first, second, third] = dogsStore.dogNames

  if (third) {
    return "Calculating your puppers' meal plans…"
  }

  if (first && second) {
    return `Calculating ${first} and ${second}'s meal plans…`
  }

  return `Calculating ${first}'s meal plan…`
})

const setSuitableRecipes = (): void => {
  const step = useRecipesStep()

  step.data.dogs.forEach((dog, index) => {
    const suitableRegularRecipes = recommendedMeals.suitableRecipesForDog(index)
    const suitableTherapeuticRecipes = recommendedMeals.therapeuticRecipesForDog(index)

    const recipes: Recipe[] = []

    // If there are suitable regular recipes, add them to the dog's recipes
    if (suitableRegularRecipes.length > 0) {
      recipes.push(...suitableRegularRecipes)
    }
    // If there are no suitable regular recipes, add suitable therapeutic recipes
    else if (suitableTherapeuticRecipes.length > 0) {
      recipes.push(...suitableTherapeuticRecipes)
    }

    dog.recipes = recipes.map((recipe) => recipe.id)
  })

  // Check whether all dogs have suitable recipes
  data.suitableRecipes = step.data.dogs.every(({ recipes }) => recipes.length)
}

onBeforeMount(() => {
  // Skip this step if suitable recipes is already defined
  if (data.suitableRecipes !== null) {
    skipped.value = true
    emits('next')
  }
})

onMounted(async () => {
  if (!skipped.value) {
    setSuitableRecipes()
    await delay(1_000)

    emits('submit', data)
  }
})
</script>

<template>
  <div
    v-if="!skipped"
    class="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-alt tw-w-full tw-mx-auto tw-pt-32 tw-px-6 tw-space-y-10 tw-grow tw-overflow-auto tw-mb-8 tw-max-w-xl"
  >
    <h3 class="tw-h3">
      {{ message }}
    </h3>

    <LykaSpinner />

    <LykaMessage>
      <p class="tw-text-center">Did you know… You get a free Go-To Gut Helper supplement in your first box 🐶</p>
    </LykaMessage>
  </div>
</template>
