import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import type { Plugin } from 'vue'
import { useZendesk } from '@/composables/useZendesk'

const isMobile = (): boolean => document.body.clientWidth < 768

declare global {
  interface Window {
    zE?: {
      (command: string, action: string): void
    }
  }
}

export const ZendeskPlugin: Plugin = {
  // Hide the intercom dialog prompt on small devices to avoid it obstructing the screen
  async install(_, { key }: { key: string }): Promise<void> {
    if (!key) {
      return
    }

    // Check if already loaded
    if ('zE' in window) {
      return
    }

    const flag = useFlagsmith().isEnabled('feature-zendesk')

    if (flag === true) {
      const script = document.createElement('script')
      const src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`

      Object.assign(script, {
        id: 'ze-snippet',
        async: true,
        src,
      })

      document.body.appendChild(script)

      // Hide the zendesk chat widget on mobile
      script.onload = (): void => {
        if (isMobile()) {
          useZendesk().hide()
        }
      }
    }
  },
}
