import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { computed } from 'vue'
import { MealPlanType } from '@/models/MealPlan'
import { usePlanStep } from '@/steps/plan'

export const PRODUCT_NAME = 'Go-To Gut Helper'
export const PRODUCT_PRICE = 16.9

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFreeGutHelper = () => {
  const experiments = useExperiments()

  const visible = computed(() => {
    return experiments.inExperiment('known-gtgh-welcome-gift')
  })

  const enabled = computed(() => {
    const plan = usePlanStep().data.plan.type
    const hasEligiblePlan = !!plan && [MealPlanType.Full, MealPlanType.Half, MealPlanType.Starter].includes(plan)

    return visible.value && hasEligiblePlan
  })

  return {
    visible,
    enabled,
  }
}
