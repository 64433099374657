import * as Sentry from '@sentry/vue'
import type { App, Plugin } from 'vue'
import { httpClientIntegration } from '@sentry/integrations'

export interface SentryOptions {
  environment: string
  dsn: string
}

export const sentryPlugin: Plugin = {
  install(app: App, { dsn, environment }: SentryOptions): void {
    if (!dsn) {
      return
    }

    const origin = new URL(import.meta.env.VITE_LYKA_API_URL).origin
    const api = new RegExp(origin)

    Sentry.init({
      app,
      dsn,
      environment,
      integrations: [
        new Sentry.BrowserTracing(),
        Sentry.replayIntegration({
          maskAllInputs: false,
          maskAllText: false,
        }),
        httpClientIntegration({
          failedRequestStatusCodes: [[400, 500]],
          failedRequestTargets: [api],
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.02,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', api],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      sendDefaultPii: true,
    })
  },
}
