<script setup lang="ts">
import { LykaButton, LykaFieldList, LykaInput, LykaMessage } from '@lyka/ui'
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import { z } from 'zod'
import { pluralize } from '@lyka/utils'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { AgeStepData } from '@/steps/age'
import { useDogsStore } from '@/stores/dogs'
import { getDogAgeType } from '@/models/Dog'
import { BirthdayType } from '@/steps/age'
import { birthdayToAge } from '@/utils/birthdayConversion'
import { ymd } from '@/__test__/utils/ymd'
import { useFrontChat } from '@/composables/useFrontChat'

const props = defineProps<{
  stepData: AgeStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

type AgeStepDogData = AgeStepData['dogs'][number]

const MAX_AGE_YEARS = 30
const MIN_RECOMMENDED_AGE_MONTHS = 1

const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const disabled = computed(() => !props.valid)

// When the dog's age is changed we need to calculate whether the dog is an Adult or Puppy
const updateAgeType = (index: number): void => {
  const breed = dogsStore.dogPrimaryBreed(index)
  const dog = data.dogs[index]

  if (dog) {
    const birthday = dog.age.date

    if (dog.age.type === BirthdayType.Date && birthday && z.coerce.date().safeParse(birthday).success) {
      const age = birthdayToAge(birthday)
      // Sync dog age when user input birthday
      dog.age.years = age.years
      dog.age.months = age.months
    }

    if (breed) {
      const ageType = getDogAgeType(dog.age.years, dog.age.months, breed)
      dog.age.is = ageType
    }
  }
}

const updateBirthdayType = (index: number, type: BirthdayType): void => {
  const dog = data.dogs[index]

  if (dog) {
    dog.age.type = type
  }
}

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}

const getDogAge = (dog: AgeStepDogData): { years: number; months: number } => {
  if (dog.age.type === BirthdayType.Date && dog.age.date && z.coerce.date().safeParse(dog.age.date).success) {
    return birthdayToAge(dog.age.date)
  }
  return { years: dog.age.years, months: dog.age.months }
}

const belowRecommendedAge = (dog: AgeStepDogData): boolean => {
  const { years, months } = getDogAge(dog)
  return years === 0 && months <= MIN_RECOMMENDED_AGE_MONTHS
}

const now = new Date()

const minDate = ymd(new Date(now.getFullYear() - MAX_AGE_YEARS))
const maxDate = ymd(now)
</script>

<template>
  <StepsContainer>
    <form id="formAge" v-submit class="tw-space-y-4" @submit.prevent="submit">
      <div v-for="(dog, index) in data.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading> How old is {{ dogsStore.dogName(index) }}? </StepHeading>

        <div class="tw-flex tw-justify-center tw-mb-8">
          <div class="tw-flex tw-flex-row tw-bg-cream tw-p-2 tw-p tw-rounded-full tw-w-min tw-space-x-2">
            <LykaButton
              :id="`dog-birthday-btn-${index}`"
              :variant="dog.age.type === BirthdayType.Date ? 'alt' : 'subtle'"
              :transparent="dog.age.type === BirthdayType.Date ? false : true"
              @click="updateBirthdayType(index, BirthdayType.Date)"
              >Date of birth
            </LykaButton>
            <LykaButton
              :id="`dog-age-btn-${index}`"
              :variant="dog.age.type === BirthdayType.Age ? 'alt' : 'subtle'"
              :transparent="dog.age.type === BirthdayType.Age ? false : true"
              @click="updateBirthdayType(index, BirthdayType.Age)"
              >Approximate age
            </LykaButton>
          </div>
        </div>

        <LykaInput
          v-if="dog.age.type === BirthdayType.Date"
          v-model.string="dog.age.date"
          class="date-input"
          type="date"
          :name="`birthday-${index}`"
          :required="dog.age.type === BirthdayType.Date"
          :error-message="`Please enter ${dogsStore.dogName(index)}'s birthday`"
          :min="minDate"
          :max="maxDate"
          @input="updateAgeType(index)"
        />

        <LykaFieldList v-else>
          <LykaInput
            v-model.number="dog.age.years"
            :name="`age-years-${index}`"
            placeholder="Years"
            type="number"
            min="0"
            max="30"
            suffix="Years"
            pattern="[0-9]*"
            :required="dog.age.type === BirthdayType.Age && !dog.age.months"
            :error-message="`Please enter ${dogsStore.dogName(index)}'s age`"
            show-zero
            @input="updateAgeType(index)"
          />

          <LykaInput
            v-model.number="dog.age.months"
            :name="`age-months-${index}`"
            placeholder="Months"
            type="number"
            min="0"
            max="11"
            suffix="Months"
            pattern="[0-9]*"
            :required="dog.age.type === BirthdayType.Age && !dog.age.years"
            hide-error
            show-zero
            @input="updateAgeType(index)"
          />
        </LykaFieldList>

        <LykaMessage v-if="belowRecommendedAge(dog)" class="tw-mt-4">
          <p>
            Lyka is recommended for puppies that are at least {{ MIN_RECOMMENDED_AGE_MONTHS }}
            {{ pluralize(MIN_RECOMMENDED_AGE_MONTHS, 'month') }} of age.
          </p>
          <p>
            If you would like advice about weaning puppies onto Lyka or our breeder partnership program please
            <button type="button" class="tw-link" @click="useFrontChat().show()">contact us</button> to talk to our
            veterinary experts.
          </p>
        </LykaMessage>
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formAge" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>

<style lang="postcss">
.date-input::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
}

.date-input::-webkit-datetime-edit-month-field {
  text-transform: uppercase;
}

.date-input::-webkit-datetime-edit-day-field {
  text-transform: uppercase;
}

.date-input::-webkit-datetime-edit-year-field:focus {
  color: inherit;
}

.date-input::-webkit-datetime-edit-month-field:focus {
  color: inherit;
}

.date-input::-webkit-datetime-edit-day-field:focus {
  color: inherit;
}

.date-input::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23005648" d="M5.3 21.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 3.5 19.7V6.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h1.4V3.125c0-.217.07-.396.212-.538a.731.731 0 0 1 .538-.212c.233 0 .42.075.562.225a.77.77 0 0 1 .213.55V4.5H15.8V3.1c0-.2.075-.371.225-.513a.741.741 0 0 1 .525-.212c.217 0 .396.075.537.225a.74.74 0 0 1 .213.525V4.5h1.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H5.3Zm0-1.5h13.4a.292.292 0 0 0 .213-.087A.292.292 0 0 0 19 19.7v-9.4H5v9.4c0 .083.03.154.088.213A.289.289 0 0 0 5.3 20ZM5 8.8h14V6.3a.289.289 0 0 0-.087-.212A.29.29 0 0 0 18.7 6H5.3a.287.287 0 0 0-.212.088A.287.287 0 0 0 5 6.3v2.5Z"/></svg>');
}
</style>
