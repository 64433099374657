import { createApp } from 'vue'
import { createPinia } from 'pinia'

import '@lyka/tailwind-preset/globals.css'

import { segmentPlugin } from '@lyka/vue-common/plugins/segment'
import { sentryPlugin } from '@lyka/vue-common/plugins/sentry'
import { frontChatPlugin } from '@lyka/vue-common/plugins/frontChat'
import { recaptchaPlugin } from '@lyka/vue-common/plugins/recaptcha'

import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import App from './App.vue'

import { debugPlugin } from './plugins/debug'
import { submitDirective } from './directives/submit'

import env from './env'
import { ZendeskPlugin } from './plugins/zendesk'

const app = createApp(App)

app.directive('submit', submitDirective)

app.use(sentryPlugin, { dsn: env.VITE_SENTRY_DSN, environment: env.VITE_SENTRY_ENV })
app.use(segmentPlugin, { writeKey: env.VITE_SEGMENT_WRITE_KEY })
app.use(recaptchaPlugin, { siteKey: env.VITE_RECAPTCHA_SITE_KEY })
app.use(debugPlugin)

const loadChatWidget = async (): Promise<void> => {
  const zendeskEnabled = await useFlagsmith().isEnabledAsync('feature-zendesk')

  if (zendeskEnabled) {
    app.use(ZendeskPlugin, { key: env.VITE_ZENDESK_KEY })
  } else {
    app.use(frontChatPlugin, { chatId: env.VITE_FRONT_CHAT_ID, hideOnMobile: true })
  }
}

loadChatWidget()

// Have to use the following format to load Pinia for dev-tools to work:
// https://github.com/vuejs/devtools/issues/1839
app.use(createPinia()).mount('#app')
