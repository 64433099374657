import { z } from 'zod'

const NonEmptyString = z.string().min(1)
const OptionalString = z.string().optional()

const envSchema = z.object({
  VITE_LYKA_URL: NonEmptyString,
  VITE_PUBLIC_PATH: OptionalString,
  VITE_LYKA_API_URL: NonEmptyString,
  VITE_STRIPE_API_KEY: NonEmptyString,
  VITE_SEGMENT_WRITE_KEY: NonEmptyString,
  VITE_SENTRY_DSN: OptionalString,
  VITE_SENTRY_ENV: NonEmptyString,
  VITE_ADDRESS_FINDER_KEY: NonEmptyString,
  VITE_TRUSTPILOT_BUSINESSUNIT_ID: NonEmptyString,
  VITE_TRUSTPILOT_BUSINESSUNIT_NAME: NonEmptyString,
  VITE_GTM_ID: NonEmptyString,
  VITE_CUSTOMER_PORTAL_URL: NonEmptyString,
  VITE_FLAGSMITH_ENVIRONMENT_KEY: OptionalString,
  VITE_FRONT_CHAT_ID: OptionalString,
  VITE_RECAPTCHA_SITE_KEY: NonEmptyString,
  VITE_EVENT_GATEWAY_URL: NonEmptyString,
  VITE_ENABLE_CRAWLERS: NonEmptyString.optional(),
  VITE_HONEYCOMB_API_KEY: OptionalString,
  VITE_ZENDESK_KEY: OptionalString,
  VITE_STRIPE_PAYMENT_METHOD_CONFIGURATION_ID_APPLE_PAY: NonEmptyString,
})

const env = envSchema.parse(import.meta.env ?? process.env)

export default env
