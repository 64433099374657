<script setup lang="ts">
import LykaModal from '@lyka/ui/components/LykaModal.vue'
import { type MealPlanWeight } from '@/models/MealPlanWeight'
import { formatNumber } from '@/utils/formatNumber'
import { type MealPlan, TRIAL_BOX_LARGE_SERVING_SIZE_THRESHOLD, TRIAL_BOX_MEAL_SIZE_GRAMS } from '@/models/MealPlan'

defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const trailPouchPercentOfCalories = (dailyGrams: number): number => {
  return TRIAL_BOX_MEAL_SIZE_GRAMS / dailyGrams
}

const remainingDailyCaloriesPercent = (pouches: number, dailyGrams: number): number => {
  const percent = trailPouchPercentOfCalories(dailyGrams)

  const sum = pouches * percent

  return 1 - sum
}

const getPouchesPerDay = (dailyGrams: number): 1 | 0.5 => {
  if (dailyGrams >= TRIAL_BOX_LARGE_SERVING_SIZE_THRESHOLD) {
    return 1
  }

  return 0.5
}

const getServingSuggestion = (dailyGrams: number): string => {
  const pouches = getPouchesPerDay(dailyGrams)
  const remaining = remainingDailyCaloriesPercent(pouches, dailyGrams) * 100

  const fraction = pouches === 1 ? '1' : '½'

  let result = `${fraction} pouch/day`

  if (remaining > 0) {
    const ROUND_TO_NEAREST = 5 as const

    const percent = formatNumber(Math.round(remaining / ROUND_TO_NEAREST) * ROUND_TO_NEAREST)

    result += ` + ${percent}% of their usual food`
  }

  return result
}
</script>

<template>
  <LykaModal @close="emits('close')">
    <div class="tw-space-y-4">
      <h4 class="tw-h4">About the Trial Box</h4>
      <p>
        The Trial Box gives your dog a small taste of Lyka’s fresh meals, allowing them to try it alongside their
        current food before receiving their full-sized order.
      </p>

      <hr class="tw-text-mint-green" />

      <h4 class="tw-h4">Your Trial Feeding Guide</h4>

      <div v-for="mealWeight in mealWeights" :key="mealWeight.key">
        <h5 class="tw-font-bold tw-text-lg">{{ mealWeight.name }}:</h5>
        <p class="tw-font-bold">
          {{ getServingSuggestion(mealWeight.servingWeight) }}
        </p>
      </div>

      <p>
        We recommend introducing Lyka gradually as part of your dog’s diet for a smooth transition. Your Trial box will
        contain a full feeding guide.
      </p>

      <hr class="tw-text-mint-green" />

      <h4 class="tw-h4">After your trial box</h4>
      <p>
        Your next delivery will include your dogs’ full daily serving size and will be scheduled to arrive after your
        trial box. You’ll receive SMS and email reminders before it’s scheduled.
      </p>
      <p>You can update or cancel your order at anytime.</p>
    </div>
  </LykaModal>
</template>
