import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'

export const useNoCommonRecipesEvent: EventSender = () => {
  const send = (): void => {
    useSegment().track('No Common Recipes', {})
  }

  return {
    send,
  }
}
