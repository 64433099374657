// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useZendesk = () => {
  const show = (): void => {
    if ('zE' in window) {
      // First show the messenger
      window.zE?.('messenger', 'show')
      // Then open it
      window.zE?.('messenger', 'open')
    }
  }

  const hide = (): void => {
    if ('zE' in window) {
      window.zE?.('messenger', 'hide')
    }
  }

  return {
    show,
    hide,
  }
}
