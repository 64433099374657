// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFrontChat = () => {
  const loaded = !!window.FrontChat

  const show = (): void => {
    if (window.FrontChat) {
      window.FrontChat?.('show')
    }
  }

  return {
    loaded,
    show,
  }
}
