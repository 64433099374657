<script setup lang="ts">
import { computed } from 'vue'
import { LykaButton } from '@lyka/ui'
import CheckoutProductBase from './products/CheckoutProductBase.vue'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { formatNumber } from '@/utils/formatNumber'
import type { Recipe } from '@/models/Recipe'
import feedingPlanImage from '@/assets/images/steps/plans/feeding-plan.jpg'
import { StepName, useStepsStore } from '@/stores/steps'

const props = defineProps<{
  mealWeight: MealPlanWeight
  mealPlan: MealPlan
  recipes: Recipe[]
}>()

const stepsStore = useStepsStore()

const planName = computed(() => {
  return `${props.mealWeight.name}'s Meal Plan`
})

const mealPlanSize = computed(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Half:
      return `${formatNumber(props.mealWeight.servingWeight / 2)}g/day`
    default:
      return `${formatNumber(props.mealWeight.servingWeight)}g/day`
  }
})

const recipesText = computed(() => {
  return props.recipes.map((r) => r.displayText).join(', ')
})
</script>

<template>
  <CheckoutProductBase>
    <div
      class="tw-rounded-2xl tw-aspect-square tw-flex tw-items-start tw-justify-center tw-w-16 tw-relative tw-shrink-0"
    >
      <img
        :src="feedingPlanImage"
        alt="An image of your dog's meal plan"
        class="tw-object-cover tw-aspect-square tw-rounded-lg tw-shrink-0"
        width="64"
        height="64"
      />
    </div>

    <div class="tw-flex tw-flex-col tw-justify-between tw-text-alt tw-w-full">
      <div class="tw-flex tw-flex-col tw-space-y-2">
        <div class="tw-flex tw-justify-between tw-gap-2 tw-flex-wrap">
          <div>{{ planName }}</div>

          <LykaButton
            type="button"
            variant="success"
            text-link
            class="tw-ml-2"
            size="xs"
            @click="stepsStore.goToStep(StepName.Recipes)"
          >
            Edit meals
          </LykaButton>
        </div>

        <div class="tw-flex tw-justify-between tw-items-start">
          <div class="tw-text-sm tw-font-light tw-space-y-2">
            <div>{{ recipesText }}</div>
            <div class="tw-text-xs">{{ mealPlanSize }}</div>
          </div>
        </div>
      </div>
    </div>
  </CheckoutProductBase>
</template>
